import React from "react"
import Logo from "../images/newDesign/logo.svg"
import { Link } from "gatsby"
export default function NominationComplete() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1>
            <div className="row text-center">
              <div className="col-md-12">
                <img
                  src={Logo}
                  alt="1st Edition Indian ESG Network Summit and Awards 2023"
                  title="1st Edition Indian ESG Network Summit and Awards 2023"
                  width="22%"
                  style={{ marginBottom: "2px" }}
                />
                <div
                  style={{
                    marginTop: "28px",
                    marginBottom: "40px",
                  }}
                >
                  1st Edition Summit and Awards 2023
                </div>
              </div>
            </div>
          </h1>
        </div>
      </div>
      <hr />
      <hr />
      <div className="text-success text-center" style={{ fontSize: "36px" }}>
        Nomination Sucessful.
      </div>
      <hr />
      <div className="text-center pb-5 mb-4" style={{ fontSize: "32px" }}>
        Nomination Submitted for <br />
        1st edition Indian ESG Network
        <br />
        concerned team will get in touch with you.
        <br />
        For any queries, please reach out to +91 70420 03364
        <hr />
        <Link to="/">Back to Home Page!</Link>
      </div>
    </>
  )
}
